import { ItemConverter } from "../../model/item/Converters";
import { Item } from "../../model/item/ItemTypes";
import { TItemApi } from "./ApiTypes";
import { toGetCategoryResult, toGetItemResult, toSaveResult, toStringArrayResult, toStringResult } from "./Results";
import * as Api from "./TactinApi";

const MODULE = "ItemModule";

export const ItemApi = (token: string): TItemApi => ({
    getItem: (itemId: number) =>
        Api.sendRequest(MODULE, "getItem", { itemID: itemId }, token).then(toGetItemResult),
    performItemAction: (itemDiff: Partial<Item>) =>
        Api.sendRequest(MODULE, "performItemAction", { item: ItemConverter.toApi(itemDiff) }, token).then(toSaveResult),
    saveAsNew: (itemDiff: Partial<Item>) =>
        Api.sendRequest(MODULE, "saveAsNew", { item: ItemConverter.toApi(itemDiff) }, token).then(toSaveResult),
    getNewItemFromTemplate: (templateId: number) =>
        Api.sendRequest(MODULE, "getNewItemFromTemplate", { templateId }, token).then(toGetItemResult),
    getCategory: (categoryId: number) =>
        Api.sendRequest(MODULE, "getCategory", { categoryId }, token).then(toGetCategoryResult),
    getRefferingItemNumbers: (itemId: number) =>
        Api.sendRequest(MODULE, "getRefferingItemNumbers", { itemID: itemId }, token).then(toStringArrayResult),
    individualizeItem: (itemId: number, categoryId?: number) =>
        Api.sendRequest(MODULE, "individualizeItem", { itemID: itemId, categoryId }, token).then(toGetItemResult),
    restoreItem: (itemId: number, categoryId?: number) =>
        Api.sendRequest(MODULE, "restoreItem", { itemID: itemId, categoryId }, token).then(toGetItemResult),
    restoreSeries: (itemId: number) =>
        Api.sendRequest(MODULE, "restoreSeries", { itemID: itemId }, token).then(toSaveResult),
    getItemShowAs: (itemId: number) =>
        Api.sendRequest(MODULE, "getItemShowAs", { itemID: itemId }, token).then(toStringResult),
    getItemsShowAs: (itemIds: number[]) =>
        Api.sendRequest(MODULE, "getItemsShowAs", { itemIDs: itemIds }, token).then(toStringArrayResult),
    getItemDescription: (itemId: number) =>
        Api.sendRequest(MODULE, "getItemDescription", { itemID: itemId }, token).then(toStringResult),
    clearDeletedFlag: (itemId: number) => 
        Api.sendRequest(MODULE, "clearDeletedFlag", {itemID: itemId}, token).then(toStringResult),
})
