import { LoginResult } from "../../utils/api/Results";
import { ClientItem, Item } from "../item/ItemTypes";
import { ActionContextProvider } from "../systemactions/ActionContext";

export class TactinEvent {
}

export class CloseItemEvent extends TactinEvent {
    cardId: string;
    itemId: number;
    message: string;

    constructor(cardId: string = '', itemId: number = 0, message: string = '') {
        super();
        this.cardId = cardId;
        this.itemId = itemId;
        this.message = message;
    }
}

export class LoginEvent extends TactinEvent {
    loginData: LoginResult;

    constructor(loginData: LoginResult) {
        super();
        this.loginData = loginData;
    }
}

export class LogoutEvent extends TactinEvent { }

export class OpenConfigurationEvent extends TactinEvent {
    headline: string;
    icon: string;
    formFields: any[]; //PropertyControlConfiguration[]
    resultConfiguration: object;

    constructor(formHeadline: string, formIcon: string, formFields: any[], resultConfiguration: object) {
        super();
        this.headline = formHeadline;
        this.icon = formIcon;
        this.formFields = formFields;
        this.resultConfiguration = resultConfiguration;
    }
}

export class OpenItemEvent extends TactinEvent {
    cardId: string = '';
    id: number = 0;
    categoryId: number = 0;
    template: boolean = false;
    item?: Item;
    clientItem: ClientItem | null = null;
    contextMap: { [key: string]: string } = {};
    contextProvider?: ActionContextProvider;

    byItemId(id: number) {
        this.id = id;
        return this;
    }
    byType(categoryId: number, cardId: string = '', contextMap: { [key: string]: string } = {}, contextProvider?: ActionContextProvider) {
        this.categoryId = categoryId;
        this.cardId = cardId;
        this.contextMap = contextMap;
        this.contextProvider = contextProvider;

        return this;
    }
    byItem(item: Item) {
        this.item = item;
        return this;
    }
    byClientItem(clientItem: ClientItem) {
        this.clientItem = clientItem;
        return this;
    }
}

export class ReloadConfigEvent extends TactinEvent {
    reason: string;

    constructor(reason: string) {
        super();
        this.reason = reason;
    }
}

export class ReplaceItemEvent extends OpenItemEvent {
    closedItemId?: number;
    message: string;

    constructor(message: string = '', cardId: string = '', closedItemId?: number) {
        super();
        this.cardId = cardId;
        this.closedItemId = closedItemId;
        this.message = message;
    }
}

export class SessionHeartbeatEvent extends TactinEvent { }

export class DownloadFileEvent extends TactinEvent {
    id: number;
    token: string;

    constructor(fileToken: string);
    constructor(fileId: number);
    constructor(file: any) {
        super();
        if (typeof file === 'string') {
            this.id = 0;
            this.token = file;
        } else {
            this.id = file;
            this.token = '';
        }
    }
}

export class ShowMessageEvent extends TactinEvent {
    message: string;

    constructor(message: string) {
        super();
        this.message = message;
    }
}

export class ShowCustomUI extends TactinEvent {
    uiName: string;
    configuration: any;
    onClose?: (signal: string, state: any) => Promise<void>;

    constructor(uiName: string, configuration: any, onClose?: (signal: string, state: any) => Promise<void>) {
        super();
        this.uiName = uiName;
        this.configuration = configuration;
        this.onClose = onClose;
    }
}

export class WaitEvent extends TactinEvent {
    id: number;
    message: string;
    finished: boolean;

    constructor(id: number, message: string, finished: boolean) {
        super();
        this.id = id;
        this.message = message;
        this.finished = finished;
    }
}

export class TokenOutOfDateEvent extends TactinEvent { }
