import { Option } from '../../model/Interface';
import { ItemCategoryConverter, ItemConverter } from '../../model/item/Converters';
import { ClientItem, Item, ItemCategory } from '../../model/item/ItemTypes';
import { ColumnFilter, fromApi } from '../../model/list/ColumnFilter';
import { ListProperties } from '../../model/list/HelperTypes';
import { PermissionLevel } from '../../model/PermissionGroup';
import { HandleErrors } from './ApiErrorHandler';
import * as Api from './TactinApi';

function toType<T extends Api.ServerActionResult>(result: Api.ServerActionResult, type: string): T {
    if (result.resultType === type)
        return result as T;
    throw new Error(`Unexpected return type! Expected ${type} but recived ${result.resultType}.`);
}

export type BooleanResult = {
    value: boolean;
} & Api.ServerActionResult;

export type FileTokenResult = {
    fileToken: string;
} & Api.ServerActionResult;

export type FileUploadResult = {
    fileIds: Map<string, number>;
} & Api.ServerActionResult;

export type FilterListResult = {
    columnFilters: ColumnFilter[];
} & Api.ServerActionResult;

export type GetCategoryResult = {
    category: ItemCategory;
} & Api.ServerActionResult;

export type GetItemResult = {
    item: Item;
    permissionLevel: PermissionLevel;
} & Api.ServerActionResult;

export type GetOptionsResult = {
    options: Option[];
} & Api.ServerActionResult;

export type GetPropertiesResult = {
    listDataProviderProperties: ListProperties;
} & Api.ServerActionResult;

export type IntegerResult = {
    value: number;
} & Api.ServerActionResult;

export type ListDataResult = {
    listData: {
        columns: [string, string][];
        data: string[][];
    };
    timestamp: number;
} & Api.ServerActionResult;

export type LoginResult = {
    languageId: number;
    loggedUser: Item;
    token: string;
    asAdmin: boolean;
} & Api.ServerActionResult;

export type RawResult = {
    value?: any;
    values?: any[];
} & Api.ServerActionResult;

export type SaveResult = {
    idSubstitutionMap: { [key: number]: number; }
} & Api.ServerActionResult;

export type SerializableObjectArrayResult<T> = {
    objectType: string;
    value: T[];
} & Api.ServerActionResult;

export type SerializableObjectResult<T> = {
    objectType: string;
    value: T;
} & Api.ServerActionResult;

export type ShowAdditionalConfigurationResult = {
    formConfiguration: any;
    resultConfiguration: any;
} & Api.ServerActionResult;

export type StringArrayResult = {
    value: string[];
} & Api.ServerActionResult;

export type StringMapResult = {
    value: { [key: string]: string };
} & Api.ServerActionResult;

export type StringResult = {
    value: string;
} & Api.ServerActionResult;

export function toBooleanResult(result: Api.ServerActionResult): Promise<boolean> {
    return new Promise((resolve, reject) => {
        const booleanResult = toType<BooleanResult>(result, 'BooleanResult');
        if (!booleanResult)
            reject(new Api.ExecutionError('Wrong response from server!'));
        else
            resolve(booleanResult.value);
    });
}
export function toFileTokenResult(result: Api.ServerActionResult): Promise<string> {
    return new Promise((resolve, reject) => {
        const fileTokenResult = toType<FileTokenResult>(result, 'FileTokenResult');
        if (!fileTokenResult)
            reject(new Api.ExecutionError('Wrong response from server!'));
        else
            resolve(fileTokenResult.fileToken);
    });
}
export function toFileUploadResult(result: Api.ServerActionResult): Promise<FileUploadResult> {
    return new Promise((resolve, reject) => {
        const fileUploadResult = toType<FileUploadResult>(result, 'FileUploadResult');
        if (!fileUploadResult)
            reject(new Api.ExecutionError('Wrong response from server!'));
        else {
            const resultMap = new Map<string, number>();
            for (const path in (fileUploadResult.fileIds as any)) {
                if (Object.prototype.hasOwnProperty.call((fileUploadResult.fileIds as any), path)) {
                    const element = Number((fileUploadResult.fileIds as any)[path]);
                    resultMap.set(path, element);
                }
            }
            fileUploadResult.fileIds = resultMap;
            resolve(fileUploadResult);
        }
    });
}
export function toFilterListResult(result: Api.ServerActionResult): Promise<ColumnFilter[]> {
    return new Promise((resolve, reject) => {
        const filtersResult = toType<FilterListResult>(result, 'GetFiltersResult');
        if (!filtersResult)
            reject(new Api.ExecutionError("Wrong response from server!"));
        else
            resolve(fromApi(filtersResult.columnFilters));
    });
}
export function toGetCategoryResult(result: Api.ServerActionResult): Promise<ItemCategory> {
    return new Promise((resolve, reject) => {
        const getCategoryResult = toType<GetCategoryResult>(result, 'GetCategoryResult');
        if (!getCategoryResult)
            reject(new Api.ExecutionError('Wrong response from server!'));
        else
            resolve(ItemCategoryConverter.fromApi(getCategoryResult.category));
    });
}
export function toGetItemResult(result: Api.ServerActionResult): Promise<ClientItem> {
    return new Promise((resolve, reject) => {
        const getItemResult = toType<GetItemResult>(result, 'GetItemResult');
        if (!getItemResult)
            reject(new Api.ExecutionError('Wrong response from server!'));
        else
            resolve({
                item: ItemConverter.fromApi(getItemResult.item),
                permission: getItemResult.permissionLevel
            });
    });
}
export function toGetOptionsResult(result: Api.ServerActionResult): Promise<Option[]> {
    return new Promise((resolve, reject) => {
        const getOptionsResult = toType<GetOptionsResult>(result, 'GetOptionsResult');
        if (!getOptionsResult)
            reject(new Api.ExecutionError('Wrong response from server!'));
        else
            resolve(getOptionsResult.options);
    });
}

export const toIntegerResult = (result: Api.ServerActionResult) => toType<IntegerResult>(result, 'IntegerResult');

export function toListDataResult(result: Api.ServerActionResult): Promise<ListDataResult> {
    return new Promise((resolve, reject) => {
        const listDataResult = toType<ListDataResult>(result, 'GetItemListResult');
        if (!listDataResult)
            reject(new Api.ExecutionError('Wrong response from server!'));
        else {
            if (listDataResult.listData && typeof listDataResult.listData === 'string'){
                let listDataString = "" + listDataResult.listData;
                listDataString = listDataString.replace(/\t/g, "   ");
                try {
                    let parsed = JSON.parse(listDataString);
                    listDataResult.listData = parsed;
                  } catch (error) {
                    HandleErrors()(new Api.ExecutionError(error));
                    reject(new Api.ExecutionError('Error parsing response from server!'));
                    return;
                }
            }
            resolve(listDataResult);
        }
    });
}

export function toListProperties(result: Api.ServerActionResult): Promise<ListProperties> {
    return new Promise((resolve, reject) => {
        const getPropertiesResult = toType<GetPropertiesResult>(result, 'GetPropertiesResult');
        if (!getPropertiesResult)
            reject(new Api.ExecutionError('Wrong response from server!'));
        else
            resolve(getPropertiesResult.listDataProviderProperties);
    });
}

export function toLoginResult(result: Api.ServerActionResult): Promise<LoginResult> {
    return new Promise((resolve, reject) => {
        const loginResult = toType<LoginResult>(result, 'LoginResult');
        if (!loginResult)
            reject(new Api.ExecutionError('Wrong response from server!'));
        else
            resolve({
                ...loginResult,
                loggedUser: ItemConverter.fromApi(loginResult.loggedUser)
            });
    });
}

export const toRawResult = (result: Api.ServerActionResult) => toType<RawResult>(result, 'RawResult');

export const toSaveResult = (result: Api.ServerActionResult) => toType<SaveResult>(result, 'SaveResult');

export function toSerializableObjectArrayResult<T>(result: Api.ServerActionResult, type: string): Promise<T[]> {
    return new Promise((resolve, reject) => {
        const res = toType<SerializableObjectArrayResult<T>>(result, 'SerializableObjectArrayResult');
        if (!res || res.objectType !== type)
            reject(new Api.ExecutionError('Wrong response from server!'));
        else
            resolve(res.value);
    });
}
export function toSerializableObjectResult<T>(result: Api.ServerActionResult, type: string): Promise<T> {
    return new Promise((resolve, reject) => {
        const res = toType<SerializableObjectResult<T>>(result, 'SerializableObjectResult');
        if (!res || res.objectType !== type)
            reject(new Api.ExecutionError('Wrong response from server!'));
        else
            resolve(res.value);
    });
}

export const toShowAdditionalConfigurationResult = (result: Api.ServerActionResult) => toType<ShowAdditionalConfigurationResult>(result, 'ShowAdditionalConfigurationResult');

export function toStringArrayResult(result: Api.ServerActionResult): Promise<string[]> {
    return new Promise((resolve, reject) => {
        const stringArrayResult = toType<StringArrayResult>(result, 'StringArrayResult');
        if (!stringArrayResult)
            reject(new Api.ExecutionError('Wrong response from server!'));
        else
            resolve(stringArrayResult.value)
    });
}

export function toStringMapResult(result: Api.ServerActionResult): Promise<Map<string, string>> {
    return new Promise((resolve, reject) => {
        const stringMapResult = toType<StringMapResult>(result, 'StringMapResult');
        if (!stringMapResult)
            reject(new Api.ExecutionError('Wrong response from server!'));
        else {
            const m = new Map();
            for (let key in stringMapResult.value)
                m.set(key, stringMapResult.value[key]);
            resolve(m);
        }
    });
}
export function toStringResult(result: Api.ServerActionResult): Promise<string> {
    return new Promise((resolve, reject) => {
        const stringResult = toType<StringResult>(result, 'StringResult');
        if (!stringResult)
            reject(new Api.ExecutionError('Wrong response from server!'));
        else
            resolve(stringResult.value)
    });
}
