import React from 'react';
import { ClientItem, Item } from '../../model/item/ItemTypes';
import { Option } from '../../model/Interface';
import General from './GeneralInfo';
import { tactin } from '../../utils/TactinGlobals';
import ListSubPanel from './ListSubPanel';
import { ActionContextProvider } from '../../model/systemactions/ActionContext';
import { Notification } from '../systemactions/SystemAction';
import SeriesConfigTemp from './SeriesConfigTemp';
import ElementList from './ElementList';
import UIFactory from '../core/UIFactory';
import { UserAddOnPanel } from './useraddon/UserAddOnPanel';
import { SimplifiedSeriesPanel } from './series/SimplifiedSeriesPanel';
import { CardSubPanelWrapper } from './CardSubPanelWrapper';

type SubPanelProps = {
    option: Option;
    original: ClientItem;
    item: ClientItem;
    onChange: (item: Item) => void;
    contextProvider?: ActionContextProvider;
    notifyHandler?: (note: Notification) => void;
    className?: string;
}

export default function SubPanel(props: SubPanelProps) {
    const primaryConfig = props.option.handlerConfiguration || {}
    const standardConfig = tactin().standardHandler(props.option.handlerClass) || {};
    const config = {
        ...standardConfig,
        ...primaryConfig
    };

    switch (props.option.handlerClass) {
        case 'General':
            return <General
                itemData={props.item}
                configuration={config}
                columnConfig={[
                    standardConfig.columns || [],
                    primaryConfig.columns || []
                ]}
                onChange={props.onChange}
                contextProvider={props.contextProvider}
                notifyHandler={props.notifyHandler} />;
        case 'ListSubPanel':
            return <ListSubPanel
                itemData={props.item}
                configuration={config} />
        case 'ElementListSubPanel':
            return <ElementList
                itemData={props.item}
                configuration={config}
                onChange={props.onChange}
                contextProvider={props.contextProvider}
                notifyHandler={props.notifyHandler} />;
        case 'UserSubPanel':
            return <UserAddOnPanel
                original={props.original}
                itemData={props.item}
                configuration={config}
                onChange={props.onChange} />
        case 'modifiedFieldConfig':
            return <SeriesConfigTemp itemData={props.item} field='modifyConfig' onChange={props.onChange} />
        case 'generationConditionConfig':
            return <SeriesConfigTemp itemData={props.item} field='generationConfig' onChange={props.onChange} />
        case 'realizationConditionConfig':
            return <SeriesConfigTemp itemData={props.item} field='realizationConfig' onChange={props.onChange} />
        case "simplifiedSeries":
            return <SimplifiedSeriesPanel original={props.original} itemData={props.item} configuration={config} onChange={props.onChange} />;
        default:
            return <CardSubPanelWrapper configuration={config}>
                {UIFactory.getViewerPanel(props.option.handlerClass, primaryConfig, standardConfig, props.item.item.itemID).main}
            </CardSubPanelWrapper>
    }
}
