export type DataProvider = {
    id: number,
    name: string,
    type: DataProviderType,
    sql?: string,
    configuration: {},
    itemTypeId?: number,
    columns: Column[],
    containsElements: boolean,
    [key: string]: any
}

export type Column = {
    id: number,
    type: ColumnType,
    column: string,
    alias: string,
    format: string,
    sort: string,
    visible: boolean,
    property?: number
    filterable: boolean,
    order: number,
    width: string,
    sum: string,
    filterConfig: {},
    group?: number,
    refColumn?: number, // this should be a column Name (id + name)
    refAlias?: string,
    [key: string]: any
}

export type DataProviderType = "item" | "manual" | "item_manual" | "mixed" | "joined" | "template";
export type ColumnType = "automatic" | "text" | "decimal" | "date" | "integer" | "bit";

export const DataProviderConverter = {
    toApi: (dp: DataProvider, original?: DataProvider): {} => {
        let result: any;
        if(original) {
            result = { id: original.id }
            for(let name in dp)
                if(original[name] && original[name] === dp[name])
                    continue;
                else
                    result[name] = dp[name];
        } else {
            result = {...dp}
        }
        if(result.type)
            switch(result.type as DataProviderType)
            {
                case "item": result.type = 0; break;
                case "manual": result.type = 1; break;
                case "item_manual": result.type = 2; break;
                case "mixed": result.type = 3; break;
                case "joined": result.type = 4; break;
                case "template": result.type = 5; break;
            };
        if(original) {
            result.columns = []
            for(let col of dp.columns) {
                const origCol = original.columns.find(c => c.id === col.id);
                if(origCol)
                    result.columns.push(ColumnConverter.toApi(col, origCol));
                else
                    result.columns.push(ColumnConverter.toApi(col));
            }
            for(let origCol of original.columns) {
                const dpCol = (<any[]>result.columns).find(c => c.id === origCol.id);
                if(!dpCol)
                    result.columns.push({id: origCol.id, remove: true});
            }
        }
        result.columns = (<any[]>result.columns).map((c, i) => ({...c, order: (i+1) * 10}))
        return result;
    },
    fromApi: (dp: any): DataProvider => {
        let id: number = dp.id ? dp.id : 0;
        let name: string = dp.name ? dp.name : "";
        let type: DataProviderType = "item";
        if(dp.type) {
            switch(dp.type)
            {
                case 0: type = "item"; break;
                case 1: type = "manual"; break;
                case 2: type = "item_manual"; break;
                case 3: type = "mixed"; break;
                case 4: type = "joined"; break;
                case 5: type = "template"; break;
            }
        }
        let sql: string | undefined = undefined;
        if(dp.sql)
            sql = dp.sql;
        let configuration = dp.configuration !== undefined ? dp.configuration : {};
        let itemTypeId: number | undefined = undefined;
        let containsElements: boolean = dp.containsElements || false;
        if(dp.itemTypeId)
            itemTypeId = dp.itemTypeId;
        let columns: Column[] = [];
        if(dp.columns && dp.columns.map) {
            columns = (<any[]>dp.columns).map(v => ColumnConverter.fromApi(v));
        }

        return { id, name, type, sql, configuration, itemTypeId, columns, containsElements }
    }
}
export const ColumnConverter = {
    toApi: (dpc: Column, original?: Column): {} => {
        let result: any;
        if(original) {
            result = { id: original.id }
            for(let name in dpc)
                if(original[name] && original[name] === dpc[name])
                    continue;
                else
                    result[name] = dpc[name];
        } else {
            result = {...dpc};
        }
        if(result.type)
            switch(result.type as ColumnType)
            {
                case "automatic": result.type = 0; break;
                case "text": result.type = 1; break;
                case "decimal": result.type = 2; break;
                case "date": result.type = 3; break;
                case "integer": result.type = 4; break;
                case "bit": result.type = 5; break;
            };
        for(let name of ["column", "alias", "format", "sort", "property", "width", "sum", "filterConfig"])
            if(!result[name])
                result[name] = undefined;
        return result;
    },
    fromApi: (dpc: any): Column => {
        let id: number = dpc.id ? dpc.id : 0;
        let type: ColumnType = 'automatic';
        if(dpc.type) {
            switch(dpc.type)
            {
                case 0: type = "automatic"; break;
                case 1: type = "text"; break;
                case 2: type = "decimal"; break;
                case 3: type = "date"; break;
                case 4: type = "integer"; break;
                case 5: type = "bit"; break;
            }
        }
        let column: string = dpc.column ? dpc.column : "";
        let alias: string = dpc.alias ? dpc.alias : "";
        let format: string = dpc.format ? dpc.format : "";
        let sort: string = dpc.sort ? dpc.sort : "";
        let visible: boolean = dpc.visible ? dpc.visible : false;
        let property: number = dpc.property ? dpc.property : undefined;
        let filterable: boolean = dpc.filterable ? dpc.filterable : false;
        let order: number = dpc.order ? dpc.order : 0;
        let width: string = dpc.width ? dpc.width : "";
        let sum: string = dpc.sum ? dpc.sum : "";
        let filterConfig: {} = dpc.filterConfig !== undefined ? dpc.filterConfig : {};
        let group: number = dpc.group ? dpc.group : undefined;
        let refColumn: number = dpc.refColumn ? dpc.refColumn : undefined;
        let refAlias: string = dpc.refAlias ? dpc.refAlias : undefined;

        return { id, type, column, alias, format, sort, visible, property, filterable,
            order, width, sum, filterConfig, group, refColumn, refAlias }
    }
}

export const DataProviderTypeNames = {
    ['item']: "Item list",
    ['manual']: "Manual SQL list",
    ['item_manual']: "Item manual SQL list",
    ['mixed']: "Mixed list",
    ['joined']: "Joined list",
    ['template']: "Template list"
}

export const ColumnTypeNames = {
    ['automatic']: "Automatic",
    ['text']: "Text column",
    ['decimal']: "Decimal column",
    ['date']: "Date column",
    ['integer']: "Integer column",
    ['bit']: "Bit column"
}

export const validateDataProvider = (dp: DataProvider) => {
    if((dp.type === "manual" || dp.type === "item_manual") && !dp.sql)
        return 'manual data provider must have a SQL query defined';
    return '';
}
