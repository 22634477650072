export type FileLink = {
    id: number,
    name: string,
    usages: number
}

export type Translation = {
    id: number
    original: string,
    translated: string
}

export type Language = {
    id: number,
    name: string
    translations: Translation[]
}

export const validateLanguage = (lng: Language) => {
    if(!lng.name)
        return 'language has to have a name';
    return '';
}

export type TextTemplate = {
    id: number,
    name: string,
    text: string,
    order: number
}
export function emptyTextTemplate() : TextTemplate {
    return {
        id: 0,
        name: '',
        text: '',
        order: 0
    }
}

export const validateTextTemplate = (template: TextTemplate) => {
    if(!template.name)
        return 'template must have a name';
    if(!template.text)
        return 'template must have a body';
    return '';
}

export type Report = {
    id: number;
    name: string;
    xml: string;
    dynamic: boolean;
}
export function emptyReport() : Report {
    return {
        id: 0,
        name: '',
        xml: '',
        dynamic: false
    }
}

export const validateReport = (report: Report) => {
    if(!report.name)
        return 'report must have a name';
    if(!report.xml)
        return 'report must have a definition';
    return '';
}

export type SystemInfo = {
    key: string;
    value?: string;
    level: 'client' | 'server';
}

export const SystemInfoConverter = {
    toApi: (si: SystemInfo) => ({
        ...si,
        level: si.level === 'server' ? 0 : 1
    }),
    fromApi: (obj: any): SystemInfo => ({
        key: obj.key || '',
        value: obj.value || undefined,
        level: (obj.level !== undefined && obj.level === 0) ? 'server' : 'client'
    })
}

export const newKey = (): SystemInfo => ({
    key: '',
    level: 'client'
});

export const validateSystemInfo = (si: SystemInfo) =>
    !si.key ? 'system info key cannot be empty' : '';

export type CategoryProperty = {
    id: number;
    property: number;
    displayLevel: number;
    order: number;
    config?: {};
    defaultValue?: string;
}

export function emptyCategoryProperty() : CategoryProperty {
    return {
        id: 0,
        property: 1,
        displayLevel: 1,
        order: 0
    }
}

export const PropertyConverter = {
    toApi: (prp: CategoryProperty, original?: CategoryProperty): {} => {
        let result: any;
        if(original) {
            result = { id: original.id }
            for(let name in prp)
                if((original as any)[name] && (original as any)[name] === (prp as any)[name])
                    continue;
                else
                    result[name] = (prp as any)[name];
        } else {
            result = {...prp};
        }
        for(let name of ['config', 'defaultValue'])
            if(!result[name])
                result[name] = undefined;
        return result;
    },
    fromApi: (dpc: any): CategoryProperty => {
        let id: number = dpc.id ? dpc.id : 0;
        let property: number = dpc.property ? dpc.property : 1;
        let displayLevel: number = dpc.displayLevel ? dpc.displayLevel : 1;
        let order: number = dpc.order ? dpc.order : 0;
        let config: {} = dpc.config !== undefined ? dpc.config : {};
        let defaultValue: string = dpc.defaultValue ? dpc.defaultValue : undefined;

        return { id, property, displayLevel, order, config, defaultValue }
    }
}

export type CategoryElement = {
    id: number;
    visible: boolean;
    categoryId: number;
}

export function emptyCategoryElement(): CategoryElement {
    return {
        id: 0,
        visible: true,
        categoryId: 1
    }
}

export const ElementConverter = {
    toApi: (elm: CategoryElement, original?: CategoryElement): {} => {
        let result: any;
        if(original) {
            result = { id: original.id }
            for(let name in elm)
                if((original as any)[name] && (original as any)[name] === (elm as any)[name])
                    continue;
                else
                    result[name] = (elm as any)[name];
        } else {
            result = {...elm};
        }
        for(let name of ['visible', 'categoryId'])
            if(!result[name])
                result[name] = undefined;
        return result;
    },
    fromApi: (dpc: any): CategoryElement => {
        let id: number = dpc.id ? dpc.id : 0;
        let visible: boolean = dpc.visible ? dpc.visible : true;
        let categoryId: number = dpc.categoryId ? dpc.categoryId : 1;

        return { id, visible, categoryId }
    }
}

export type Category = {
    id: number;
    type: number;
    name: string;
    description: string;
    icon: string;
    showAsScript: string;
    cardUsagePlace?: number;
    cardTemplateUsagePlace?: number;
    validators?: {};
    properties: CategoryProperty[];
    elements: CategoryElement[];
    permissions: number[];
}

export function emptyCategory() : Category {
    return {
        id: 0,
        type: 1,
        name: '',
        description: '',
        icon: '',
        showAsScript: '',
        properties: [],
        elements: [],
        permissions: []
    }
}

function copyArray(field: string, result: any, changed: any, original: any, converter: { toApi: (v: any, o?: any) => any}) {
    result[field] = []
    for(let elm of changed[field]) {
        const origCol = original[field].find((c: any) => c.id === elm.id);
        if(origCol)
            result[field].push(converter.toApi(elm, origCol));
        else
            result[field].push(converter.toApi(elm));
    }
    for(let origCol of original[field]) {
        const dpCol = (<any[]>result[field]).find(c => c.id === origCol.id);
        if(!dpCol)
            result[field].push({id: origCol.id, remove: true});
    }
}

export const CategoryConverter = {
    toApi: (cat: Category, original?: Category): {} => {
        let result: any;
        if(original) {
            result = { id: original.id }
            for(let name in cat)
                if((original as any)[name] && (original as any)[name] === (cat as any)[name])
                    continue;
                else if((original as any)[name] && (cat as any)[name] === undefined)
                    result[name] = null;
                else
                    result[name] = (cat as any)[name];
        } else {
            result = {...cat}
        }
        if(original) {
            copyArray('elements', result, cat, original, ElementConverter);
            copyArray('properties', result, cat, original, PropertyConverter);
        }
        if(result.properties)
            result.properties = (<any[]>result.properties).map((c, i) => ({...c, order: (i+1) * 10}))

        return result;
    },
    fromApi: (dp: any): Category => {
        let id: number = dp.id ? dp.id : 0;
        let type: number = dp.type ? dp.type : '';
        let name: string = dp.name ? dp.name : '';
        let description: string = dp.description ? dp.description : '';
        let icon: string = dp.icon ? dp.icon : '';
        let showAsScript: string = dp.showAsScript ? dp.showAsScript : '';
        let validators: {} | undefined = dp.validators ? dp.validators : undefined;
        
        let cardUsagePlace: number | undefined = dp.cardUsagePlace;
        let cardTemplateUsagePlace: number | undefined = dp.cardTemplateUsagePlace;
        let properties: CategoryProperty[] = [];
        if(dp.properties && dp.properties.map) {
            properties = (<any[]>dp.properties).map(v => PropertyConverter.fromApi(v));
        }
        let elements: CategoryElement[] = [];
        if(dp.elements && dp.elements.map) {
            elements = (<any[]>dp.elements).map(v => ElementConverter.fromApi(v));
        }
        let permissions: number[] = dp.permissions ? dp.permissions : [];

        return { id, type, name, description, icon, showAsScript, properties, elements, permissions, cardUsagePlace, cardTemplateUsagePlace, validators }
    }
}

export function validateCategory(cat: Category) : string {
    if(!cat.name)
        return 'name cannot be empty';
    if(cat.type <= 0)
        return 'category type must be set';
    return '';
}
