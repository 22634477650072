import { useState, useRef } from 'react';
import { HandleErrors } from './api/ApiErrorHandler';
import { TypedApi } from './api/ApiTypes';
import { Failed } from './api/TactinApi';

export type EntityStore<T> = {
    getEntities: () => T[];
    loadEntities: () => void;
    reloadEntity: (entity: T) => void;
    saveEntity: (entity: T, original?: T) => Promise<boolean>;
    saveEntities: (entities: [T, T?][]) => Promise<boolean>;
    entityUsages: (entity: T) => Promise<false | { entity: T, usage: object }>;
    removeEntity: (entity: T) => Promise<boolean>;
    getLastUpdatedId: () => number;
}

export default function useEntityStore<T extends { id: number }>(
    api: TypedApi<T>,
    validate: (e: T) => string,
    isComponentExist: () => boolean
): EntityStore<T> {
    let lastUpdatedId: number = -1;

    const [getEntities, setEntities, loadEntities] = useAsyncArray<T[]>([],
        api.list, HandleErrors(), isComponentExist)

    const reloadEntity = (entity: T) => {
        if(getEntities().find(e => e.id === entity.id))
            return setEntities(getEntities().map(e => e.id === entity.id ? entity : e));
        else
            return setEntities([...getEntities(), entity]);
    }
    const saveEntity = (entity: T, original?: T) => {
        const msg = validate(entity);
        if(msg)
            return Promise.reject(new Failed(`Cannot perform a save: ${msg}.`)).catch(HandleErrors());
        else
            return api
            .save(entity, original)
            .then(v => {
                if(!v.id){
                    lastUpdatedId = -1;
                    return Promise.reject(new Failed('Failed to save entity!'));
                }
                else{
                    lastUpdatedId = v.id;
                    return setEntities(v.list);
                }
            })
            .catch(HandleErrors());
    }
    const saveEntities = (entities: [T, T?][]) => {
        const msgs = entities.filter(v => validate(v[0]));
        if(msgs.length > 0)
            return Promise.reject(new Failed(`Cannot perform a collective save.`)).catch(HandleErrors());
        else
            return api
            .saveAll(entities)
            .then(v => {
                if(!v.id)
                    return Promise.reject(new Failed('Failed to save entity!'));
                else
                    return setEntities(v.list);
            })
            .catch(HandleErrors());
    }
    const entityUsages = (entity: T) =>
        api
        .usage(entity.id)
        .then(u => Promise.resolve({entity, usage: u}))
        .catch(HandleErrors());
    const removeEntity = (entity: T) =>
        api
        .remove(entity.id)
        .then(setEntities)
        .catch(HandleErrors());

    const getLastUpdatedId = () => lastUpdatedId;

    return {
        getEntities,
        loadEntities,
        reloadEntity,
        saveEntity,
        saveEntities,
        entityUsages,
        removeEntity,
        getLastUpdatedId,
    }
}

export function useAsyncArray<T>(
    defaultValue: T,
    loadEntities: () => Promise<T>,
    errorHandler: (error: any) => boolean,
    isComponentExist?: () => boolean
): [() => T, (e: T) => boolean, () => void] {

    const [entities, _setEntities] = useState<T | null>(null);
    const loading = useRef(false);

    const setEntities = (e: T) => {
        _setEntities(e);
        loading.current = false;
        return true;
    }
    const load = () => {
        loading.current = true;
        loadEntities()
            .then((r) => isComponentExist ? isComponentExist() && setEntities(r) : setEntities(r))
            .catch(errorHandler);
    }
    const getEntities = () => {
        if(!entities && !loading.current)
            load();
        return entities || defaultValue;
    };
    return [getEntities, setEntities, load];
}

export function useExistenceCheck() {
    const isExist = useRef(true);

    const checkExistence = () => !!isExist.current;
    const setUnexist = () => isExist.current = false;
    return {
        checkExistence,
        setUnexist
    }
}
