import React, { useEffect, useRef, useState } from 'react';
import { BarePropertyControlProps } from './PropertyControl';
import DatePicker from 'react-datepicker';
import './react-datepicker.css';
import { WithLabel } from './LabeledControl';
import { DateUtils } from '../../utils/DateUtils';
import { Language, SystemInfo } from '../../model/ConfigurationEntities';
import { tactin } from '../../utils/TactinGlobals';
import { useExistenceCheck } from '../../utils/EntityStore';
import { HandleErrors } from '../../utils/api/ApiErrorHandler';
import { api } from '../../utils/api/ApiProvider';

export function BareDateControl({ value, readOnly, className = '', configuration, onChange }: BarePropertyControlProps) {
    
    const divRef = useRef<HTMLDivElement>(null);
    const datePickerRef = useRef<DatePicker>(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const systemInfo = JSON.parse(tactin().configuration.systemInfo('regionalSettings'));
    
    useEffect(() => {
        const eventClick = (e: MouseEvent) => {
            if (!divRef.current?.contains((e.target as HTMLElement)))
                datePickerRef.current?.setOpen(false);
        }
        if (isPopupOpen) {
            document.addEventListener('click', eventClick);
            return () => document.removeEventListener('click', eventClick);
        }
    }, [isPopupOpen])
    
    let format = configuration.format ? configuration.format : 'yyyy-MM-dd';
    let locale:any = "EN-en";
    let calendarStartDay:number = 0;
    if (systemInfo){
        const regionalSettings = systemInfo;
        format = regionalSettings.dateFormat ? regionalSettings.dateFormat : format;
        locale = regionalSettings.locale ? regionalSettings.locale : locale;
        calendarStartDay = typeof(regionalSettings.calendarStartDay) != "undefined" ?  regionalSettings.calendarStartDay >> 0 : 0;
        
        if (locale !== "EN-en"){
            switch(locale){
                case "PL-pl":
                    const days = ['Nie','Pon', 'Wto', 'Śro', 'Czw', 'Pią', 'Sob'];
                    const months = ['Styczeń', "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"];
                    calendarStartDay = 1;
                    locale = {
                        localize: {
                            day: (n:number) => days[n],
                            month: (n:number) => months[n]
                        },
                        formatLong: {
                            date: () => format
                        },
                        options: {
                            weekStartsOn: calendarStartDay, 
                        }
                    }
                break;
                default:
                    locale = undefined;
            }
        }
    }
    
    const inlineStyle = className === 'inline-edit' ? 'relative' : 'inherit';
    
    return (
        <div className={className} onKeyDown={e => e.key==='Escape' && isPopupOpen && e.stopPropagation()} ref={divRef} style={{position: inlineStyle}}>
            <DatePicker
                ref={datePickerRef}
                selected={value ? new Date(value) : null}
                dateFormat={format}
                strictParsing
                disabled={readOnly}
                locale={locale}
                className='dataPicker dateTextBox'
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                showWeekNumbers             
                dropdownMode='select'
                todayButton={tactin().configuration.translate('Today')}
                onChange={d => {
                    const showAs = d instanceof Date ? DateUtils.formatDate(d, format) : '';
                    onChange(d, showAs);
                }}
                onKeyDown={e => {
                    if (e.key==='Escape' && datePickerRef.current?.isCalendarOpen) {       
                        //if Escape press closes the datepicker popup then prevent event propagation that would close the item card too                 
                        e.stopPropagation();
                    }
                    ['Escape', 'Enter'].includes(e.key) && datePickerRef.current?.setBlur()
                    
                }}                     
                onCalendarOpen={() => setIsPopupOpen(true)}
                onCalendarClose={() => setIsPopupOpen(false)}/>
        </div>);
}

export const DateControl = WithLabel(BareDateControl);
